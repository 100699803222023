import { memo, useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import MainLayout from '../../components/Layout/MainLayout';
import {
  ButtonsContainer,
  StepForm,
  StepFormContent,
} from '../TaxingFormProcess/styles';
import Button from '../../components/Button';
import SvgIcon from '../../components/SvgIcon';
import Arrow from '../../components/svgs/Arrow';
import { Block } from '../SuccessResponse/styles';
import Sad from '../../components/svgs/Sad';
import useUpdateClient from '../../hooks/useUpdateClient';
// import { SECTIONS_PATHS } from '../../utils/data';
import { ThemeInterface } from '../../components/Theme';
import { GlobalContext } from '../../context';

import sideBg from '../../images/side-bg/congratulation-img.png';
import usePaymentResponse from '../../hooks/usePaymentResponse';
import { responseMessages } from '../messages';

function FailedResponse() {
  const isAllowed = usePaymentResponse();

  const { t } = useTranslation();

  const { handleUpdateClient } = useUpdateClient();

  useEffect(() => {
    if (isAllowed) {
      handleUpdateClient('PAIEMENT_ECHOUE');
    }
  }, [isAllowed, handleUpdateClient]);

  const {
    state: { themeMode },
  } = useContext(GlobalContext);
  const theme = useTheme();
  return (
    <MainLayout
      title={t(responseMessages.failedResponse.title())}
      subtitle={t(responseMessages.failedResponse.subtitle())}
      backgroundImage={sideBg}
    >
      <StepForm>
        <StepFormContent>
          <Block>
            <Sad />
            <h2> {t(responseMessages.failedResponse.body.heading())}</h2>
            <br />
            <p>
              {t(responseMessages.failedResponse.body.description())}{' '}
              {/* <Link to={`/${SECTIONS_PATHS.RAFFLE}`}>
                {t(responseMessages.failedResponse.body.link())} !
              </Link> */}
            </p>
          </Block>
        </StepFormContent>
        <ButtonsContainer>
          <Button
            onClick={async e => {
              e.preventDefault();
              await navigate('/');
            }}
            big
            ghost
            htmlType="button"
            title={t(responseMessages.failedResponse.action.goBack())}
            icon={
              <SvgIcon
                iconColor={themeMode === 'light' ? 'inherit' : '#fff'}
                style={{
                  transform: 'rotate(180deg)',
                  marginRight: (theme as ThemeInterface).rtl ? 0 : 10,
                  marginLeft: (theme as ThemeInterface).rtl ? 10 : 0,
                }}
              >
                <Arrow />
              </SvgIcon>
            }
            iconPosition="left"
            rounded
          />

          <span />
        </ButtonsContainer>
      </StepForm>
    </MainLayout>
  );
}

export default memo(FailedResponse);
